<template>
  <div class="comp-dialog">
    <el-dialog  :fullscreen="dialogFull"  :visible.sync='dialogs' width="800px" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              添加企业
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
      label-width="80px"
      :model="form"
      label-position="top"
      class="form-class"
      :rules="rules"
      ref="dialog">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="系统名称" prop='system_name'>
              <el-input class='form-input' v-model="form.system_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业类型" prop='company_type'>
              <el-select v-model="form.company_type" placeholder="请选择企业类型">
                <el-option v-for="item in options" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="企业名称" prop='name'>
              <el-input class='form-input' v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <!-- 暂时不用状态功能 后端容易出问题 先写死 -->
          <!-- <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="form.checked">
                <el-radio-button label="1">通过</el-radio-button>
                <el-radio-button label="0">未通过</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="邮箱" prop='email'>
              <el-input class='form-input' v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop='phone'>
              <el-input class='form-input' v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="结束时间" prop='end_time'>
              <el-date-picker
                v-model="form.end_time"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addComp } from '@/api/saasManage'
export default {
  name:'AddDialog',
  props: {
     dialog:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      dialogs:false,
      dialogFull:false,
      form:{
        end_time:0, // 结束时间
        phone:'',//手机号
        email:'',//邮箱
        checked:1, // 是否通过
        name:'', //企业名称
        company_type:'', // 企业类型
        system_name:'' // 系统名称
      },
      rules:{},
      options:["监理","施工","甲方","设计"]
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
  },
  methods: {
    /**
     * @desc: 保存
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          addComp(this.form).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave');
            this.form = {
              end_time:0, // 结束时间"
              phone:'',//手机号
              email:'',//邮箱
              checked:1, // 是否通过
              name:'', //企业名称
              company_type:'', // 企业类型
              system_name:'' // 系统名称
            };
          }).catch(() => {
            this.$message.error(`操作错误`);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.dialogs = false;
      this.form = {
        end_time:0, // 结束时间"
        phone:'',//手机号
        email:'',//邮箱
        checked:1, // 是否通过
        name:'', //企业名称
        company_type:'', // 企业类型
        system_name:'' // 系统名称
      };
      this.$emit('dialogClose');
    },
  },
};
</script>
<style lang="less">
.comp-dialog{
  .form-class{
    height:310px;
    overflow:auto;
    padding-bottom: 10px;
  }
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
