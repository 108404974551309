<template>
  <div class="comp-main">
    <div class='main-title'>
      <div class="main-label">企业管理</div>
      <div>
        <!-- 新增暂时不要 -->
        <!-- <el-button size='mini' type="primary" icon="el-icon-plus" @click="dialog=true">新增</el-button> -->
        <!-- <el-button v-if="parentSelectId && parentSelectId.length" size='mini' type="danger" icon="el-icon-delete-solid" @click="onSelectDelete()">删除</el-button> -->
        <!-- <el-button size='mini' icon="el-icon-download" >导出</el-button> -->
      </div>
    </div>
    <el-table
    v-loading='loading'
    :data="compData"
    class="comp-table"
    :header-cell-style="{background:'#fafafa'}"
    @selection-change="onSelectChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="企业名称" prop="name" width="200"></el-table-column>
      <el-table-column label="企业类型" prop="company_type"> </el-table-column>
      <el-table-column label="版本" prop="version_id">
        <template #default="{ row }" >
          <div>{{versionName(row.version_id)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at"  align="left" width="150px"></el-table-column>
      <el-table-column label="操作"  align="center" width='200px'>
        <template #default="{ row }" >
          <el-button v-if="judgingAPermission(['comp.update'])" size="mini" type="text" @click="onEdit(row)">修改</el-button>
          <!--todo 张瑜接口没写 企业管理不让删 -->
            <!-- <el-popconfirm
              class="delete-btn"
              title="这是一段内容确定删除吗？"
              @confirm='onDelete(row)'
            >
              <el-button size="mini" type="text" slot="reference">删除</el-button>
            </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 编辑抽屉 -->
    <EditDrawer 
    :drawer='drawer' 
    :editData='editData' 
    @drawerClose='drawerClose' 
    @drawerSave='drawerSave'>
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog 
    :dialog='dialog'
    @dialogClose='dialogClose' 
    @dialogSave='dialogSave' >
    </AddDialog>
  </div>
</template>

<script>
import { judgingAPermission } from '@/utils/tools'
import { getComp, detComp } from '@/api/saasManage'
import EditDrawer from './EditDrawer.vue'
import AddDialog from './AddDialog.vue'
export default {
  components:{
    AddDialog,
    EditDrawer,
  },
  props: {},
  data() {
    return {
      judgingAPermission: judgingAPermission,
      parentSelectId:[],
      loading:false,
      compData:[],
      drawer:false,
      dialog:false,
      editData:{},
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      search: '',
    };
  },
  methods: {
    /**
     * @desc: 返回版本信息
     */
    versionName(index){
      switch(index){
        case 1 : return '个人'
        case 2 : return '企业'
      }
    },
    /**
     * @desc:关闭新增弹窗
     */
    dialogClose(){
      this.dialog = !this.dialog;
    },
    /**
     * @desc:新增弹窗点击保存按钮
     */
    dialogSave(){
      this.dialog = !this.dialog;
      this.getData()
    },
    /**
     * @desc:修改抽屉点击保存按钮
     */
    drawerSave(){
      this.drawer = !this.drawer;
      this.getData()
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose(){
      this.drawer = !this.drawer;
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row){
      this.editData = row;
      this.drawer = !this.drawer;
    },
    /**
     * @desc:打开新增弹窗
     */
    onAdd(){
      this.dialog = !this.dialog;
    },
    /**
     * @desc:普通删除
     */
    onDelete(row){
      detComp({id:[row.id]}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
     * @desc: 批量删除
     */
    onSelectDelete(){
      detComp({id:this.parentSelectId}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
     * @desc:筛选
     */
    onSearch(data){
      this.search = data;
      this.getData();
    },
    onRefresh(){
      this.search = '';
      this.getData();
    },
     /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val){
      this.parentSelectId = [];
      val.forEach(data=>{
        this.parentSelectId.push(data.id);
      })
    },
    // /**
		//  * @desc: 得到企业管理列表
		//  */
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.search
      };
      getComp(params).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.compData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
  mounted() {
     this.getData();
  }
};
</script>
<style lang='less'>
@import "../../../../css/manageMain.less";
.comp-main {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  .comp-table{
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}
</style>